.settings-container {
  position: relative;
  width: 80%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.settings-title {
  text-align: center;
  color: #333;
}

.settings-form {
  display: flex;
  flex-direction: column;
}

.setting-item {
  margin-bottom: 20px;
}

.settings-label {
  margin-top: 20px;
  font-size: 1.2em;
}

.settings-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.submit-button, .logout-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
}


.logout-button {
  background-color: #dc3545;
  color: white;
}

.phone-input-group {
  display: flex;
  gap: 10px;
}

.add-button {
  padding: 8px 12px;
  cursor: pointer;
}

.phone-number-list {
  margin-top: 20px;
}

.phone-number-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

