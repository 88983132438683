/* Main container */
.inventory-edit-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    /* Center horizontally */
    box-sizing: border-box;
    margin-bottom: 50px;
    width: 100%;
    /* Ensure container takes up full width */
}

/* Outer wrapper to center the entire page */
.center-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* Full height of the viewport */
    padding: 20px;
    /* Optional padding */
    box-sizing: border-box;
    /* Ensure padding doesn't cause overflow */
    background-color: #f5f5f5;
    /* Background color for visual appeal */
}

/* Products list */
.products-list {
    margin-bottom: 20px;
}

/* Product item (grid layout for larger screens) */
.product-item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding: 20px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
    width: 100%;
    box-sizing: border-box;
    /* Prevent padding from causing overflow */
}

/* Form group */
.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    font-size: 0.9rem;
    margin-bottom: 5px;
}

input, select {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%; /* Ensure inputs and selects take up full width */
    box-sizing: border-box; /* Prevent padding from causing overflow */
    height: 42px; /* Ensure consistent height for input and select elements */
}

/* Additional styling specifically for select elements */
select {
    padding-right: 35px; /* Adjust right padding to move arrow closer to the left */
    appearance: none; /* Remove default browser styling */
    background: url('data:image/svg+xml;charset=US-ASCII,<svg%20xmlns="http://www.w3.org/2000/svg"%20viewBox="0%200%204%205"><polygon%20fill="%23ccc"%20points="0,0%204,0%202,3"/></svg>') no-repeat right 10px center; /* Custom arrow */
    background-size: 10px; /* Size of the arrow */
    background-color: #ffffff; /* Match the background color */
}

/* Delete button */
.delete-btn {
    grid-column: 1 / span 3;
    padding: 10px;
    font-size: 1rem;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
    /* Prevent padding from causing overflow */
}

.delete-btn:hover {
    background-color: #e60000;
}

/* Action buttons */
.action-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.suggested-price-container {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.suggested-price {
    margin-right: 10px;
}

.fill-suggested-price-btn {
    padding: 5px 10px;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
    .inventory-edit-container {
        padding: 15px;
        max-width: 100%;
        box-sizing: border-box;
    }

    /* Stack the grid layout for smaller screens */
    .product-item {
        grid-template-columns: 1fr;
        /* One column on mobile */
        gap: 10px;
        width: 100%;
        box-sizing: border-box;
    }

    input,
    select {
        font-size: 0.9rem;
        /* Slightly smaller inputs and selects for mobile */
        width: 100%;
        box-sizing: border-box;
    }

    .delete-btn {
        grid-column: 1 / span 1;
        width: 100%;
        box-sizing: border-box;
    }

    .action-buttons {
        flex-direction: column;
        /* Stack buttons vertically */
        gap: 10px;
    }

    button {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
}

@media (max-width: 480px) {
    .inventory-edit-container {
        padding: 10px;
        box-sizing: border-box;
    }

    .product-item {
        padding: 15px;
        gap: 8px;
        width: 100%;
        box-sizing: border-box;
    }

    input,
    select {
        padding: 8px;
        font-size: 0.85rem;
        width: 100%;
        box-sizing: border-box;
    }

    .delete-btn {
        padding: 8px;
        font-size: 0.9rem;
        width: 100%;
        box-sizing: border-box;
    }

    button {
        font-size: 0.9rem;
        padding: 8px;
        width: 100%;
        box-sizing: border-box;
    }
}