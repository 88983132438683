/* General Styling */
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
    line-height: 1.6;
    padding: 20px;
    background-color: #F5F7FA;

    /* Light background for contrast */
}

h2 {
    color: #003366;
    /* Primary color for headings */
}

/* Header */
.navbar {
    background-color: #FFFFFF;
    /* White header for logo visibility */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    position: relative;
    z-index: 1000;
    border-bottom: 2px solid #003366;
    /* Primary color for bottom border */
}

.navbar-logo img {
    height: 50px;
}

.navbar-links {
    display: flex;
    align-items: center;
}

.navbar-links ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar-links ul li {
    margin-left: 20px;
}

.navbar-links ul li a {
    text-decoration: none;
    color: #003366;
    /* Primary color for links */
    font-weight: bold;
    font-size: 16px;
    transition: color 0.3s ease, border-bottom 0.3s ease;
}

.navbar-links ul li a:hover {
    color: #002244;
    /* Darker shade for hover */
    border-bottom: 2px solid #002244;
    /* Underline matches hover color */
}

.active-link {
    color: #003366;
    /* Primary color for active links */
    border-bottom: 2px solid #003366;
    /* Match underline with primary color */
}

/* Buttons */
button {
    background-color: #003366;
    /* Primary color for buttons */
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

button:hover {
    background-color: #002244;
    /* Darker shade for hover */
    opacity: 0.9;
}

.delete-button {
    background: none;
    border: none;
    color: #FF5722;
    /* Red for delete buttons */
    cursor: pointer;
    font-size: 15px;
    margin-left: 5px;
}

.cancel-button {
    background-color: #6c757d;
    /* Neutral gray for cancel buttons */
    color: white;
}

/* File Upload Container */
.file-upload-container {
    border: 2px dashed #003366;
    border-radius: 12px;
    background-color: #FFFFFF;
    color: #333;
    text-align: center;
    padding: 30px;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.file-upload-container:hover {
    background-color: #E0F7FA;
    /* Light teal for hover */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Table */
.inventory-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.inventory-table th {
    background-color: #003366;
    /* Primary color for table headers */
    color: #FFFFFF;
    /* White text for headers */
    padding: 12px;
    text-align: left;
    font-size: 14px;
}

.inventory-table td {
    padding: 10px;
    border-bottom: 1px solid #DDD;
    text-align: left;
}

.inventory-table tr:nth-child(even) {
    background-color: #F9FAFB;
    /* Light gray for alternating rows */
}

.inventory-table tr:hover {
    background-color: #E0F7FA;
    /* Subtle highlight for hover */
}

/* Footer */
.footer {
    background-color: #003366;
    /* Primary color for footer */
    color: #FFFFFF;
    /* White text */
    text-align: center;
    padding: 20px 0;
    font-size: 14px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .navbar-links ul {
        flex-direction: column;
    }

    .navbar-links ul li {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .inventory-table th,
    .inventory-table td {
        font-size: 14px;
        padding: 8px;
    }
}

@media (max-width: 480px) {

    .inventory-table th,
    .inventory-table td {
        font-size: 12px;
        padding: 6px;
    }
}