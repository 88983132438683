.footer {
  background-color: #003366; /* Deep blue to match the logo */
  color: #FFFFFF; /* White text for readability */
  text-align: center;
  padding: 15px 0; /* Slightly increased padding for better spacing */
  font-size: 14px; /* Adjusted font size for a clean look */
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
  z-index: 1000; /* Ensures it stays above other elements */
}