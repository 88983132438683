input[type="file"] {
  margin-top: 10px;
}

/* Add or Upload container */
.add-or-upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.manual-add-container,
.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inventory-container {
  padding-bottom: 50px;
  /* Adds bottom padding to ensure the table doesn't touch the footer */
}

.manual-add-button {
  padding: 10px 20px;
  background-color: #003366;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Divider for the "or" text */
.divider-or {
  margin: 0 20px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

/* File upload container, ensuring it doesn't extend beyond the margin */
.file-upload-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border: 2px dashed #003366;
  border-radius: 12px;
  background-color: #fafafa;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  max-width: 100%;
  box-sizing: border-box;
}

.file-upload-container.drag-over {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px); /* Blurred effect */
  border-color: #007bff;
  transform: scale(1.05); /* Slight zoom-in effect */
  transition: all 0.3s ease-in-out;
}

.file-upload-container:hover {
  background-color: #f0f0f0;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.15);
}

/* Hover effect for upload container */
.file-upload-container:hover {
  background-color: #f0f0f0;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.15);
}

/* Full-size label for the upload area */
.custom-file-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #003366;
  cursor: pointer;
  text-align: center;
  position: relative;
}

/* Hidden file input, covering the full area */
.custom-file-upload input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0;
  left: 0;
}

/* Ensure the text inside the upload box retains its original style */
.upload-box {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

/* Display the selected file name below the upload box */
.file-name {
  margin-left: 10px;
  font-style: italic;
  color: #003366;
  font-size: 16px;
}

/* Button for adding inventory */
.add-inventory-button {
  background-color: #003366;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 10px;
}

.add-inventory-button:hover {
  background-color: #002244;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.file-upload-container .file-name {
  margin-left: 10px;
  font-style: italic;
  color: #666;
  font-size: 16px;
}

/* Inventory Table Wrapper */
.inventory-table-wrapper {
  max-width: 100%;
  overflow-x: auto;
  /* Allow horizontal scrolling for smaller screens */
  margin: 0 20px 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* Align table to top */
}

/* Table Container */
.table-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inventory-table {
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  /* Prevents text wrapping in the table */
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

th,
td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #003366;
  color: white;
  font-size: 14px;
}

tbody tr:nth-child(odd) {
  background-color: #f5f5f5;
}

tbody tr:hover {
  background-color: #ddd;
  cursor: pointer;
}

/* Column width adjustments */
td:nth-child(1) {
  width: 10%;
}

td:nth-child(2),
td:nth-child(3),
td:nth-child(4),
td:nth-child(5),
td:nth-child(6),
td:nth-child(7),
td:nth-child(8),
td:nth-child(9),
td:nth-child(10),
td:nth-child(11),
td:nth-child(12) {
  width: 8%;
}

/* Action buttons */
.action-cell button {
  background-color: #f44336;
  border: none;
  padding: 5px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.action-cell button:hover {
  background-color: #e53935;
}

.inventory-actions {
  margin-left: 20px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.inventory-actions button {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.inventory-actions button.cancel-button {
  background-color: #9e9e9e;
}

.inventory-actions button:hover {
  opacity: 0.9;
}

/* Center the search container and adjust width */
.search-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.search-input {
  width: 400px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #003366;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-width: 90%;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
}

/* Modal Body */
.modal-body {
  margin-top: 20px;
}

.distributor-input-container {
  position: relative;
  width: 100%;
  /* Ensures the input container respects the input width */
}

.distributor-input-container input {
  width: 100%;
  /* The input box spans the full width of the container */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  /* Ensures padding does not exceed width */
}

.distributor-suggestions {
  border: 1px solid #ccc;
  background: white;
  position: absolute;
  z-index: 10;
  width: 90%;
  /* Matches the width of the input box */
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 2px;
  /* Slight spacing below the input box */
}

.suggestion-item {
  padding: 8px 10px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  font-size: 14px;
}

.suggestion-item:last-child {
  border-bottom: none;
  /* Remove bottom border for the last item */
}

.suggestion-item:hover {
  background: #f0f0f0;
}



/* Modal Footer */
.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  /* Place Cancel on left and Confirm on right */
}






/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .add-or-upload-container {
    flex-direction: column;
  }

  .search-container {
    flex-direction: column;
    align-items: center;
  }

  .search-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .inventory-table-wrapper {
    overflow-x: auto;
  }

  .inventory-table {
    width: 100%;
    white-space: nowrap;
  }

  .inventory-table th,
  .inventory-table td {
    font-size: 14px;
    padding: 8px;
  }

  .pagination {
    text-align: center;
  }

  .pagination button {
    margin: 5px;
  }

  .footer {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .search-container {
    flex-direction: column;
    align-items: center;
  }

  .search-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .inventory-table-wrapper {
    overflow-x: auto;
  }

  .inventory-table {
    width: 100%;
    white-space: nowrap;
  }

  .inventory-table th,
  .inventory-table td {
    font-size: 12px;
    padding: 6px;
  }

  .search-input,
  .select-element {
    font-size: 14px;
    padding: 4px;
  }
}