/* General container styling */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #F5F5F5;
}

/* Login box */
.login-box {
    width: 400px;
    padding: 40px;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Logo */
.zaiko-logo {
    width: 100px;
    margin-bottom: 20px;
}

/* Title and subtitle */
.login-title {
    font-size: 20px;
    color: #333333;
    margin-bottom: 10px;
    font-weight: bold;
}

.login-subtitle {
    font-size: 14px;
    color: #666666;
    margin-bottom: 30px;
}

/* Input fields */
.login-box input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #CCCCCC;
    border-radius: 6px;
    font-size: 14px;
    color: #333333;
}

.login-box input::placeholder {
    color: #999999;
}

/* Login button */
.login-button {
    width: 100%;
    padding: 12px;
    background-color: #003366;
    color: #FFFFFF;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
}

.login-button:hover {
    background-color: #002244;
}

/* Forgot Password link */
.forgot-password,
.go-back {
    margin-top: 10px;
    color: #003366;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
}

.forgot-password:hover,
.go-back:hover {
    color: #002244;
    font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
    .login-box {
        width: 90%;
        padding: 20px;
    }

    .login-box button {
        font-size: 14px;
    }
}
