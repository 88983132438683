.header {
  display: flex;
  align-items: center;
  gap: 0px;
  margin-bottom: 10px;
}

.feedback-container {
  position: relative;
  width: 80%;
  margin: 40px auto;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.feedback-image {
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;
}

.feedback-title {
  text-align: center;
  color: #003366;
}

.feedback-subtitle {
  margin-top: 20px;
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0px;
}

.rating {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.star {
  font-size: 4em;
  cursor: pointer;
  color: #ccc;
}

.star.selected {
  color: #FDD836;
}

.feedback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: 0 auto;
}

.feedback-label {
  margin-top: 0px;
  font-size: 1.2em;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.feedback-input {
  width: 100%;
  height: 150px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.actions {
  display: flex;
  justify-content: space-between;
  /* Space between buttons */
  gap: 20px;
  /* Add spacing between buttons */
  width: 100%;
  /* Align with parent width */
}

.submit-button {
  background-color: #003366;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #002244;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: #5a6268;
}

@media (max-width: 767px) {
  .feedback-container {
    width: 100%;
  }

  .feedback-form {
    width: 100%;
  }

  .rating {
    font-size: smaller;
  }

  .star {
    font-size: 5em;
    color: #ccc;
  }

  .actions {
    flex-direction: column;
    /* Stack buttons vertically on small screens */
    gap: 10px;
  }
}